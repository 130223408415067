import { ReactNode } from "react";
import classes from "./ScheduleEntry.module.css";
import ScheduleEntryEvent from "./ScheduleEntryEvent";

type Props = {
  details: string;
  image: ReactNode;
  name: string;
  time: string;
  direction?: "ltr" | "rtl";
};

const ScheduleEntry = (props: Props) => {
  const { details, direction = "ltr", image, name, time } = props;

  const eventComponent = (
    <ScheduleEntryEvent
      details={details}
      name={name}
      reverse={direction === "rtl"}
      time={time}
    />
  );

  const imageComponent = <div className={classes.image}>{image}</div>;

  return (
    <li className={classes.root}>
      <div className={classes.left}>
        {direction === "ltr" ? eventComponent : imageComponent}
      </div>
      <div className={classes.right}>
        {direction === "ltr" ? imageComponent : eventComponent}
      </div>
    </li>
  );
};

export default ScheduleEntry;
