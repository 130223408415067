import AppSection from "../AppSection/AppSection";
import SectionTitle from "../SectionTitle/SectionTitle";
import { ReactComponent as ChampagneGlassSvg } from "./res/champagne_glass.svg";
import { ReactComponent as ConfettiSvg } from "./res/confetti.svg";
import { ReactComponent as DinnerSvg } from "./res/dinner.svg";
import { ReactComponent as GateSvg } from "./res/gate.svg";
import { ReactComponent as MarriageCertificateSvg } from "./res/marriage_certificate.svg";
import classes from "./ScheduleSection.module.css";
import ScheduleEntry from "./ScheduleEntry";

const SCHEDULE_ENTRIES = [
  {
    icon: <GateSvg className={classes.imgRes} />,
    timeFrame: "16:30",
    what: "Accoglienza",
    where: "Pergola",
  },
  {
    icon: <MarriageCertificateSvg className={classes.imgRes} />,
    timeFrame: "17:00",
    what: "Cerimonia",
    where: "Cedro",
  },
  {
    icon: <ChampagneGlassSvg className={classes.imgRes} />,
    timeFrame: "17:30",
    what: "Aperitivo",
    where: "Giardino",
  },
  {
    icon: <DinnerSvg className={classes.imgRes} />,
    timeFrame: "19:30",
    what: "Cena",
    where: "Salone",
  },
  {
    icon: <ConfettiSvg className={classes.imgRes} />,
    timeFrame: "22:30",
    what: "Party & open bar",
    where: "Cantinone",
  },
];

const ScheduleSection = () => {
  return (
    <AppSection>
      <SectionTitle title="Programma" />
      <ol className={classes.agenda}>
        {SCHEDULE_ENTRIES.map(({ icon, what, where, timeFrame }, index) => (
          <ScheduleEntry
            details={where}
            direction={index % 2 === 0 ? "ltr" : "rtl"}
            image={icon}
            key={what}
            name={what}
            time={timeFrame}
          />
        ))}
      </ol>
    </AppSection>
  );
};

export default ScheduleSection;
