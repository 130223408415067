import { NavigationOutlined } from "@mui/icons-material";
import { Button, Link } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";
import { useIsSmallScreen } from "../../hooks/useMediaQuery";
import AppSection from "../AppSection/AppSection";
import SectionTitle from "../SectionTitle/SectionTitle";
import classes from "./LocationSection.module.css";

const LocationSection = () => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <AppSection>
      <SectionTitle title="Villa Parravicino Sossnovsky" />
      <div className={classes.sectionContent}>
        <div className={classes.picture}></div>
        <div className={classes.text}>
          <p
            className={
              isSmallScreen ? classes.addressStack : classes.addressGrid
            }
          >
            <span className={classes.street}>Via Cesare Cantù, 15</span>
            {isSmallScreen ? null : <span aria-hidden>•</span>}
            <span className={classes.city}>22036 Erba CO</span>
          </p>
          <Link
            alignItems="center"
            component={forwardRef(
              (props, ref: ForwardedRef<HTMLButtonElement>) => (
                <Button {...props} ref={ref} variant="contained" />
              )
            )}
            display="flex"
            gap="0.25rem"
            href="https://maps.google.com/maps/dir//Villa+Parravicino+Sossnovsky+Via+Cesare+Cant%C3%B9,+15+22036+Erba+CO/@45.7972264,9.2030921,11z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4786a02c099f25a1:0x45f47d1e589fb7cf"
            target="_blank"
            underline="none"
            sx={{ color: "white", textTransform: "none" }}
          >
            <NavigationOutlined fontSize="small" />
            Indicazioni Stradali
          </Link>
        </div>
      </div>
    </AppSection>
  );
};

export default LocationSection;
