import { ContentCopyOutlined, EmailOutlined } from "@mui/icons-material";
import { Button, Divider, Paper, Snackbar } from "@mui/material";
import { useState } from "react";
import { mail_rsvp } from "../../constants";
import AppSection from "../AppSection/AppSection";
import SectionTitle from "../SectionTitle/SectionTitle";
import classes from "./RsvpSection.module.css";

const RsvpSection = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const sendMailButtonClickHandler = () => {
    window.location.href = `mailto:${window.atob(mail_rsvp)}`;
  };

  const copyMailButtonClickHandler = () => {
    navigator.clipboard.writeText(window.atob(mail_rsvp));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <AppSection>
      <SectionTitle title="Ci sarete?" />
      <div className={classes.sectionContent}>
        <p>
          Non vediamo l'ora di festeggiare insieme! Vi chiediamo gentilmente
          solo di confermare la vostra presenza per tempo: potete farlo
          scrivendoci all'indirizzo fornito di seguito.
        </p>
        <p>
          Se rispondete per più di una persona, non dimenticate di elencare ogni
          partecipante ed eventuali allergie o intolleranze alimentari. Nel caso
          di dubbi o richieste particolari, trovate i nostri recapiti nella
          sezione <i>Contatti</i>.
        </p>
        <div className={classes.paperWrapper}>
          <Paper className={classes.paper}>
            <div className={classes.paperContent}>
              <Button
                onClick={sendMailButtonClickHandler}
                size="small"
                startIcon={<EmailOutlined />}
                sx={{ textTransform: "none" }}
                variant="contained"
              >
                Conferma Via Mail
              </Button>
              <Divider className={classes.divider} variant="middle">
                oppure
              </Divider>
              <Button
                color="warning"
                onClick={copyMailButtonClickHandler}
                size="small"
                startIcon={<ContentCopyOutlined />}
                sx={{ textTransform: "none" }}
                variant="text"
              >
                Copia indirizzo
              </Button>
            </div>
            <div className={classes.envelopeFront}></div>
          </Paper>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        message="Copiato negli appunti"
        onClose={handleSnackbarClose}
        open={snackbarOpen}
      />
    </AppSection>
  );
};

export default RsvpSection;
