// Countdown
export const WEDDING_DATE_TIMESTAMP = 1687102200000;
export const FACTOR_SECONDS = 1000;
export const FACTOR_MINUTES = FACTOR_SECONDS * 60;
export const FACTOR_HOURS = FACTOR_MINUTES * 60;
export const FACTOR_DAYS = FACTOR_HOURS * 24;
// Slideshow
export const SLIDESHOW_INTERVAL = 10000;
// Contacts
export const phn_j = "KzM5MzM4MjAyMzc4OQ==";
export const phn_f = "KzM5MzUxNTUzNDMwMw==";
export const mail_j = "am1vc3NpbmFAb3V0bG9vay5jb20=";
export const mail_f = "ZnJhbmNlc2NhLm11ZmZhdHRpQG91dGxvb2suaXQ=";
export const mail_rsvp = "cGFydGVjaXBhemlvbmlAamFjb3BvZWZyYW5jZXNjYS5pdA==";
// Registry
export const IBAN = "IT98T0339512900052138286460";
export const BENEFICIARIO = "Muffatti Francesca Mossina Jacopo";
// Sections ids
export const SECTION_ID_CONTACTS = "contacts";
export const SECTION_ID_DATE = "date";
export const SECTION_ID_HOME = "home";
export const SECTION_ID_HONEYMOON = "honeymoon";
export const SECTION_ID_LOCATION = "location";
export const SECTION_ID_RVSP = "rvsp";
export const SECTION_ID_SCHEDULE = "schedule";
