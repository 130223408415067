import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SECTION_ID_HOME } from "../../constants";
import { Section, UiState } from "./types";

export const name = "ui";
const initialState: UiState = { activeSection: SECTION_ID_HOME };

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setActiveSection: (state, action: PayloadAction<Section>) => {
      state.activeSection = action.payload;
    },
    resetActiveSection: (state) => {
      state.activeSection = initialState.activeSection;
    },
  },
});

const { actions, reducer } = slice;
export const { setActiveSection, resetActiveSection } = actions;
export default reducer;
