import { useEffect, useState } from "react";
import classes from "./Countdown.module.css";
import CountdownFigure from "./CountdownFigure";
import { computeCountdownInMs, computeCountdownParts } from "../../utils";

const Countdown = () => {
  const [timeDifference, setTimeDifference] = useState(computeCountdownInMs());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(computeCountdownInMs());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { days, hours, minutes, seconds } = computeCountdownParts(timeDifference);

  return (
    <div className={classes.root}>
      <CountdownFigure value={days} figure="giorni" />
      <CountdownFigure value={hours} figure="ore" />
      <CountdownFigure value={minutes} figure="minuti" />
      <CountdownFigure value={seconds} figure="secondi" />
    </div>
  );
};

export default Countdown;
