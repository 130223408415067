import { type ReactNode } from "react";
import {
  Cover,
  DateSection,
  HoneymoonSection,
  LocationSection,
  ScheduleSection,
} from "./Components";
import { computeCountdownInMs } from "./utils";
import { selectActiveSection } from "./features/ui/selectors";
import { type Section } from "./features/ui/types";
import { useAppSelector } from "./app/hooks";
import { useIsSmallScreen } from "./hooks/useMediaQuery";
import ContactsSection from "./Components/ContactsSection/ContactsSection";
import NavigationDrawer from "./Components/NavigationDrawer/NavigationDrawer";
import RsvpSection from "./Components/RsvpSection/RsvpSection";
import classes from "./App.module.css";

const sectionComponentMap: Record<Section, ReactNode> = {
  contacts: <ContactsSection />,
  date: <DateSection />,
  home: <Cover className={classes.cover} />,
  honeymoon: <HoneymoonSection />,
  location: <LocationSection />,
  rvsp: <RsvpSection />,
  schedule: <ScheduleSection />,
};

const App = () => {
  const isSmallScreen = useIsSmallScreen();
  const activeSection = useAppSelector(selectActiveSection);
  const countdown = computeCountdownInMs();

  return (
    <div className={classes.root}>
      {isSmallScreen ? <NavigationDrawer /> : null}
      {isSmallScreen
        ? sectionComponentMap[activeSection]
        : sectionComponentMap["home"]}
      {!isSmallScreen ? (
        <main className={classes.content}>
          {sectionComponentMap["date"]}
          {sectionComponentMap["location"]}
          {sectionComponentMap["schedule"]}
          {sectionComponentMap["honeymoon"]}
          {countdown > 0 ? sectionComponentMap["rvsp"] : null}
          {countdown > 0 ? sectionComponentMap["contacts"] : null}
        </main>
      ) : null}
    </div>
  );
};

export default App;
