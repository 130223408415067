import { ComponentProps } from "react";
import classes from "./AppSection.module.css";

type Props = ComponentProps<"div">;

const AppSection = (props: Props) => {
  const { children, className, ...rest } = props;

  return (
    <section {...rest} className={[classes.root, className].join(" ")}>
      {children}
    </section>
  );
};

export default AppSection;
