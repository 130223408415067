import angra from "./angra.jpg";
import budapest from "./budapest.jpg";
import budapest_34 from "./budapest_34.jpg";
import grazzano_visconti from "./grazzano_visconti.jpg";
import grazzano_visconti_34 from "./grazzano_visconti_34.jpg";
import grignetta from "./grignetta.jpg";
import grignetta_34 from "./grignetta_34.jpg";
import linkin_park from "./linkin_park.jpg";
import livigno from "./livigno.jpg";
import livigno_34 from "./livigno_34.jpg";
import matera from "./matera.jpg";
import matera_34 from "./matera_34.jpg";
import monopoli from "./monopoli.jpg";
import monopoli_34 from "./monopoli_34.jpg";
import nizza from "./nizza.jpg";
import nizza_34 from "./nizza_34.jpg";
import perugia from "./perugia.jpg";
import perugia_34 from "./perugia_34.jpg";
import pico from "./pico.jpg";
import san_primo from "./san_primo.jpg";
import serra_do_cume from "./serra_do_cume.jpg";
import serra_do_cume_34 from "./serra_do_cume_34.jpg";
import vietri from "./vietri.jpg";
import vietri_34 from "./vietri_34.jpg";

export const pictures = [
  angra,
  budapest,
  grazzano_visconti,
  grignetta,
  linkin_park,
  livigno,
  matera,
  monopoli,
  nizza,
  perugia,
  pico,
  san_primo,
  serra_do_cume,
  vietri,
];

export const pictures_mobile = [
  angra,
  budapest_34,
  grazzano_visconti_34,
  grignetta_34,
  livigno_34,
  matera_34,
  monopoli_34,
  nizza_34,
  perugia_34,
  san_primo,
  serra_do_cume_34,
  vietri_34,
];
