import classes from "./ScheduleEntryEvent.module.css";

type Props = {
  details: string;
  name: string;
  time: string;
  reverse?: boolean;
};

const ScheduleEntryEvent = (props: Props) => {
  const { details, name, reverse = false, time } = props;

  return (
    <div className={[classes.root, reverse ? classes.reverse : ""].join(" ")}>
      <span className={classes.timeFrame}>{time}</span>
      <div className={classes.event}>
        <span className={classes.what}>{name}</span>
        <hr className={classes.divider} />
        <span className={classes.where}>{details}</span>
      </div>
    </div>
  );
};

export default ScheduleEntryEvent;
