import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import { useState } from "react";
import classes from "./Attributions.module.css";

const Attributions = () => {
  const [open, setOpen] = useState(false);

  // Event handlers
  const handleLinkClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <footer className={classes.root}>
      <span>© 2023 Jacopo Mossina • jacopoefrancesca.it</span>
      <Link href="#" onClick={handleLinkClick}>
        Attribuzioni
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle color="primary">Attribuzioni</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogText}>
            <Link
              href="https://www.flaticon.com/free-icons/wedding"
              title="wedding icons"
            >
              Wedding icons created by Freepik - Flaticon
            </Link>
            <Link
              href="https://www.flaticon.com/free-icons/gate"
              title="gate icons"
            >
              Gate icons created by Freepik - Flaticon
            </Link>
            <Link
              href="https://www.flaticon.com/free-icons/marriage-certificate"
              title="marriage certificate icons"
            >
              Marriage certificate icons created by IconBaandar - Flaticon
            </Link>
            <Link
              href="https://www.flaticon.com/free-icons/drink"
              title="drink icons"
            >
              Drink icons created by Freepik - Flaticon
            </Link>
            <Link
              href="https://www.flaticon.com/free-icons/wedding"
              title="wedding icons"
            >
              Wedding icons created by Konkapp - Flaticon
            </Link>
            <Link
              href="https://www.flaticon.com/free-icons/birthday"
              title="birthday icons"
            >
              Birthday icons created by srip - Flaticon
            </Link>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/@susannschuster?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Susann Schuster
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/EPH_IlA0Yx0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/ko/@trapnation?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Andre Benz
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/FPyGfMHXWZU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/@richardtao28?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Richard Tao
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/XGvFfx25j7A?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/@bananablackcat?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Svetlana Gumerova
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/PMkQ7WVWqr0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/@laundemrl?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Launde Morel
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/5ZYOwknJc3c?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/@taravester?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Tara Vester
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/iRSdzELUvKU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
            <span>
              Foto di{" "}
              <Link href="https://unsplash.com/@davelundgren?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                David Lundgren
              </Link>{" "}
              su{" "}
              <Link href="https://unsplash.com/it/foto/DsvRyJZae0Q?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </Link>
            </span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </footer>
  );
};

export default Attributions;
