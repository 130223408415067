import AppSection from "../AppSection/AppSection";
import Calendar from "../Calendar/Calendar";
import Countdown from "../Countdown/Countdown";
import JustMarried from "../Countdown/JustMarried";
import { computeCountdownInMs } from "../../utils";
import SectionTitle from "../SectionTitle/SectionTitle";
import classes from "./DateSection.module.css";

const DateSection = () => {
  const countdown = computeCountdownInMs();
  return (
    <AppSection className={classes.root}>
      <div className={classes.content}>
        <SectionTitle title="18 Giugno 2023" />
        <div className={classes.calendar}>
          <Calendar />
        </div>
        {countdown > 0 ? <Countdown /> : <JustMarried />}
      </div>
    </AppSection>
  );
};

export default DateSection;
